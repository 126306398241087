// 郊游
<template>
    <div class="container">
        <div class="bikeImg">
            <ul :class="started">
                <li><img src="../assets/games/2/beijingqiche1.png"></li>
                <li><img src="../assets/games/2/beijingqiche2.png"></li>
                <li><img src="../assets/games/2/beijingqiche3.png"></li>
            </ul>
        </div>
        <div class="bike">
            <img src="../assets/games/2/qiche.gif">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'game_2',
        data() {
            let that = this;
            return {
                started: '',
                player : {
                    play    : function () {
                        that.started = 'started';
                    },
                    stop    : function () {
                        that.started = 'stop';
                    },
                    pause   : function () {
                        that.started = 'paused';
                    },
                    inverted: function () {
                    }
                }
            }
        },
        mounted() {
        },
        methods: {
        }
    }
</script>

<style lang="scss" scoped>
    @keyframes play {
        from {
            margin-left: -3840px;
        }
        to {
            margin-left: 0;
        }
    }
    .container {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
    }
    .bikeImg {
        ul {
            width: 5760px;
            height: 100vh;
            li {
                width: 1920px;
                height: 100%;
                float: left;
                list-style: none;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &.started {
                animation: play 60s linear infinite;
            }
            &.paused {
                animation: play 60s linear infinite;
                animation-play-state: paused;
            }
            &.stop {
                animation: none;
            }
        }
    }
    .bike {
        position: fixed;
        bottom: 150px;
        right: 88px;
        width: 297px;
        height: 344px;
        img {
            width: 100%;
            height: 100%;
        }
    }
</style>
